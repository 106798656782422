import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Col, Row } from 'react-bootstrap'

const About = () =>  (
  <Layout>
      <Seo title="About" description="Learn about who the writers are at Deglutino"/>
      <Row className="py-5">
        <h1 className="display-5 fw-bold">About Deglutino</h1>
        <Row>
          <Col lg={11} className={`mx-auto`}>
            In the spring of 2016 the founders of Deglutino became teammates for the first time. Together they were tasked with herculean effort of supporting an acquired product which was only known by its user experience. At first, we had issues with gaining traction in the supporting the product. The tool set we used to support other products did not work in this scenario. Frankly, the tool set was just too slow for this project. We needed something else. First, we began to shed the weight of the old tools and began forming only the tools necessary to provide value. Experiment after experiment we learned what did and did not work. With each positive result, the team got closer to hitting their stride.
          </Col>
        </Row>
        <Row className={`pt-4`}>
          <Col lg={11} className={`mx-auto`}>
            <h5>So what where some of those learnings?</h5>
            <ul>
              <li>Take action on what is most valuable</li>
              <li>Communication is key</li>
              <li>Delivering value is not a solo endeavour</li>
              <li>Every team member is accountable</li>
              <li>Push each other to be better every day</li>
              <li>Hard conversations need to be had</li>
            </ul>
          </Col>
        </Row>
        <Row className={`pt-4`}>
          <Col lg={11} className={`mx-auto`}>
            Since 2016 the team has only taken these learnings further. We jump in feet first to bring teams up to their full potential.
          </Col>
        </Row>
        <Row className={`pt-4`}>
          <Col lg={11} className={`mx-auto`}>
            <h5>Unsticking the glue that is holding back a team from reaching its full potential, that is what Deglutino is all about.</h5>
          </Col>
        </Row>
      </Row>
  </Layout>
)

export default About